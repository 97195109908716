import { commonPageProps } from '@proptypes'
import getCommonPageProps from '#src/common/init/get-common-props'
import FourOhFourPage from '#src/error/FourOhFourPage'

const Custom404 = ({ settings: { fourOhFourImage } }) => {
  return <FourOhFourPage fourOhFourImage={fourOhFourImage} />
}

export const getStaticProps = async ({ locale }) => {
  const commonProps = await getCommonPageProps(locale)

  return { props: { ...commonProps }, revalidate: 5 }
}

Custom404.propTypes = {
  ...commonPageProps
}

export default Custom404
