import Translation from '../common/components/translation'
import { Grid, GridArea, Heading, Image } from '@pretamanger/component-library'
import LocaleLink from '#src/common/components/locale-link'
import { image } from '#src/common/propTypes'
import { areas, FourOhFourHeading } from './styles'

const FourOhFourPage = ({ fourOhFourImage }) => {
  const fourOhFourImageBaseUrl = fourOhFourImage.src
  const fourOhFourUrl = `${fourOhFourImageBaseUrl}?fm=jpg&fl=progressive`
  const fourOhFourImageSources = {
    xs: `${fourOhFourUrl}&w=328`,
    sm: `${fourOhFourUrl}&w=394`,
    md: `${fourOhFourUrl}&w=480`,
    lg: `${fourOhFourUrl}&w=394`,
    xl: `${fourOhFourUrl}&w=499`
  }
  const fourOhFourImageFallback = `${fourOhFourUrl}?w=499`

  return (
    <Grid className='my-16' areas={areas}>
      <GridArea name='copy'>
        <FourOhFourHeading level='h1' styleOverride='headingXs'>
          404
        </FourOhFourHeading>
        <Heading
          as='p'
          className='mb-4'
          level='h2'
          styleOverride='headingSm'
          data-testid='404-error-title'
        >
          <Translation id='errors.404.title' />
        </Heading>
        <Translation id='errors.404.copy' />
        <p className='mt-6'>
          <LocaleLink href='/'>
            <a>
              <Translation id='errors.404.go-back-home' />
            </a>
          </LocaleLink>
        </p>
      </GridArea>
      <GridArea name='image' className='xs:my-12'>
        <Image
          alt={fourOhFourImage.alt || '404'}
          sources={fourOhFourImageSources}
          img={fourOhFourImageFallback}
        />
      </GridArea>
    </Grid>
  )
}

FourOhFourPage.propTypes = {
  fourOhFourImage: image
}

export default FourOhFourPage
